.hero {
  .section-inner {
    padding-top: $hero--padding-t__mobile;
    padding-bottom: $hero--padding-b__mobile;
  }
}

.hero-inner {
  // Affects only hero full
   .hero-figure {
    margin-top: $hero--inner-padding-v_mobile;
  }

   .hero-figure {
    > a {
      display: inline-flex;
      // prevents stretching
      align-items: center;
      vertical-align: top;
    }
  }
}

@include media("<=medium") {
  .hero {
    .split-wrap {
      .split-item {
        .split-item-content {
          margin-bottom: $hero--inner-padding-v_mobile;
        }
      }

      &.invert-mobile {
        .split-item {
          .split-item-image {
            margin-bottom: $hero--inner-padding-v_mobile;
          }
        }
      }
    }
  }

  .button-join-tennessee {
    margin-left: 5px !important;
    margin-top: 10px;
  }
}

@include media(">medium") {
  .hero {
    .section-inner {
      padding-top: $hero--padding-t__desktop;
      padding-bottom: $hero--padding-b__desktop;
    }
  }

  .hero-inner {
    // Affects only hero full
      .hero-figure {
      margin-top: $hero--inner-padding-v_desktop;
    }
  }
}

.tennessee-logo {
  display: none;
  img {
    padding-top: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
}
