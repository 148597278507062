.dash-toaster {
    & > div {
      background-color: rgb(145, 139, 139);
      color:rgb(145, 139, 139);
      border-radius: 10px;
      &[class*="success"] {
        background: #25282C;
        color: #fff;
      }
      &[class*="error"] {
        background: #25282C;
        color: #fff;
      }
    }
    div[class*="progress-bar"] {
      background-color: rgb(145, 139, 139);
    }
  }